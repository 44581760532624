import { InferGetStaticPropsType } from 'next';

import { IS_PRODUCTION, VERCEL_ENV } from '@/configs';

import { RedirectSampleCourse } from '@/layouts/RedirectSampleCourse';
import { RedirectTopLayout } from '@/layouts/RedirectTopLayout';
import { getApiCourseData } from '@/notion/getApiCourseData';

export const getStaticProps = async () => {
  if (VERCEL_ENV === 'preview') {
    const allCourseData = await getApiCourseData({
      isProduction: IS_PRODUCTION,
    });

    return {
      props: {
        redirectCourse: allCourseData[0].url,
      },
    };
  }

  return {
    props: {},
  };
};

type PageProps = InferGetStaticPropsType<typeof getStaticProps>;

export default function Home({ redirectCourse }: PageProps) {
  if (VERCEL_ENV === 'preview') {
    return <RedirectSampleCourse url={redirectCourse} />;
  }

  return <RedirectTopLayout />;
}
