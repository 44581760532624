import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';

import { CATEGORY_PREFIX } from '@/configs';
import { buildPageTitle } from '@/utils/buildPageTitle';
import { getCoursePagePath } from '@/utils/getCoursePagePath';

import { SiteHead } from '@/components/common/SiteHead';

const REDIRECT_COURSE = 'design' as const;
const REDIRECT_CATEGORY = `${CATEGORY_PREFIX}design` as const;

type RedirectLayoutProps = {
  title?: string;
  description?: string;
  url?: string;
};

export const RedirectSampleCourse: FC<RedirectLayoutProps> = ({
  title,
  description,
  url,
}) => {
  const pageTitle = buildPageTitle(title);
  const redirect = url ?? getCoursePagePath(REDIRECT_COURSE, REDIRECT_CATEGORY);
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    // eslint-disable-next-line no-console
    console.log('Preview Mode');
    setTimeout(() => {
      router
        .replace(redirect)
        .then(() => {
          // MEMO: STG, Production は異なる host からアクセスしているので reload しないと json を fetch できずエラー画面が表示されてしまう
          // Application error: a client-side exception has occurred (see the browser console for more information).
          router.reload();
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Redirect error', error);
        });
    }, 300);
  }, [router]);

  return (
    <div>
      <SiteHead title={pageTitle} description={description} />
    </div>
  );
};
